import store from '../store';
import axios from 'axios';
// axios.defaults.withCredentials = true;
// axios.

// const apiUrl = store.state.apiUrl;
// const baseUrl = store.state.baseUrl;

export default {
  sendEmail(name, email, message) {
    return axios({
      method: 'post',
      url: 'https://api.blackflamedigital.com/api/mailing/printotype/send',
      data: {
        name: name,
        email: email,
        message: message
      },
      headers: { Authorization: 'Bearer 1|i2PofEH1UzeNqNCbyIuFOwe3DOGwYQp5FZs7avFM' }
    });
  },
}