<template>

  <div class="flex flex-col w-full">

    <div class="flex flex-col items-center">

      <div class="text-4xl text-pt-gray uppercase text-center font-bold mt-8 w-full">
        Contact Us
      </div>

      <div class="my-8 max-w-screen-lg text-center">
        Got a project? Need a design? want a print? If yes to one or all of these points, then feel free to send us a short email outlining the scope of your project, and we will get in touch!
      </div>

      <div class="gap-5 flex justify-center items-center my-8 max-w-screen-lg">

        <div class="flex flex-col">
          <div class="p-2">
            <input
              v-model="name"
              class="p-2 border border-pt-gray-text rounded-lg w-full"
              type="text"
              placeholder="Name"
            >
          </div>

          <div class="p-2">
            <input
              v-model="email"
              class="p-2 border border-pt-gray-text rounded-lg w-full"
              type="text"
              placeholder="Email"
            >
          </div>

          <div class="p-2">
            <textarea
              v-model="message"
              class="p-2 border border-pt-gray-text rounded-lg w-full"
              cols="60"
              rows="5"
              placeholder="Message"
            ></textarea>
          </div>

          <div class="flex justify-center">
            <div
              @click="sendMessage"
              class="bg-pt-red text-white py-2 px-4"
              :class="sending ? '' : 'cursor-pointer'"
            >
              <span v-if="!sending">
                Send Message
              </span>
              <span v-else>
                <i class="fas fa-circle-notch fa-spin"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="text-xl uppercase text-pt-gray font-bold">
            Contact Details
          </div>

          <div class="flex items-center mt-2">
            <div class="w-8 text-2xl text-pt-gray">
              <i class="fas fa-map-marker-alt"></i>
            </div>
            <div>
              <a
                class="text-pt-gray-text hover:text-pt-gray transition-all duration-200 ease-in-out text-lg"
                target="_blank"
                href="https://www.google.com/maps/place/Telford,+Telford+and+Wrekin,+UK/@52.6757748,-2.4449273,15z/data=!4m2!3m1!1s0x4870780b0d577243:0x9139ed82d06f8811?hl=en-GB"
              >
                Telford and Wrekin, UK
              </a>
            </div>
          </div>

          <div class="flex items-center mt-2">
            <div class="w-8 text-2xl text-pt-gray">
              <i class="fas fa-mobile-alt"></i>
            </div>
            <div>
              <a
                class="text-pt-gray-text hover:text-pt-gray transition-all duration-200 ease-in-out text-lg"
                target="_blank"
                href="tel:07929130201"
              >
                (+44) 07929 130 201
              </a>
            </div>
          </div>



          <div class="text-xl uppercase text-pt-gray font-bold mt-8">
            Social Contact
          </div>

          <div class="flex items-center mt-2">
            <div class="w-8 text-2xl text-pt-gray">
              <i class="fab fa-facebook-square"></i>
            </div>
            <div>
              <a
                class="text-pt-gray-text hover:text-pt-gray transition-all duration-200 ease-in-out text-lg"
                target="_blank"
                href="https://www.facebook.com/Printotype"
              >
                facebook.com/Printotype
              </a>
            </div>
          </div>

          <div class="flex items-center mt-2">
            <div class="w-8 text-2xl text-pt-gray">
              <i class="fab fa-twitter"></i>
            </div>
            <div>
              <a
                class="text-pt-gray-text hover:text-pt-gray transition-all duration-200 ease-in-out text-lg"
                target="_blank"
                href="https://twitter.com/PrintotypeLtd"
              >
                twitter.com/PrintotypeLtd
              </a>
            </div>
          </div>
        </div>

        <div class="flex flex-col">

        </div>




      </div>

    </div>

  </div>

</template>

<script>
import mailRequests from '../../helpers/mailRequests';

export default {
  mounted() {

  },
  data() {
    return {
      name: null,
      email: null,
      message: null,
      sending: false
    }
  },
  components: {

  },
  methods: {
    sendMessage() {
      if(!this.sending) {
        this.sending = true;
        mailRequests.sendEmail(this.name, this.email, this.message)
        .then((response) => {
          this.$toast.success('Sent Successfully. Thank you!', { position: "bottom" });
          this.name= null;
          this.email= null;
          this.message= null;
          this.sending = false;
        }).catch(error => {
          const errors = error.response.data.errors
          for(const err in errors) {
            this.$toast.error(errors[err][0], { position: "bottom" });
          }
          this.sending = false;
        });
      }
    }
  }
}
</script>